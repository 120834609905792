<template>
  <section id="bolcklist-monitoring-profile">
    <v-row class="d-flex justify-center" no-gutters>
      <v-col cols="12" lg="10" md="10">
        <page-header :breadcrumbs="breadcrumbs">
          <template #title>
            <v-col md="12" lg="6" class="pb-0 mt-5">
              <h5
                id="mailbox-providers__title"
                class="text-h5 font-weight-large text--primary font--secondary"
              >
                {{ title }}
              </h5>
            </v-col>
          </template>
        </page-header>
        <v-row no-gutters>
          <v-col>
            <blocklist-monitoring-profile-form />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import PageHeader from "@/components/shared/PageHeader.vue";
import BlocklistMonitoringProfileForm from "@/components/forms/BlocklistMonitoringProfileForm.vue";

/**
 * Blocklist monitoring profile view
 */
export default {
  name: "BlocklistMonitoringProfile",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { PageHeader, BlocklistMonitoringProfileForm },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * Edit profile
     * @type {Boolean}
     */
    editProfile() {
      return !!this.$route.params?.profileId;
    },
    /**
     * Title of the page
     */
    title() {
      const { blocklistProfile } = this.$dictionary.app.accountSettings;
      return this.editProfile
        ? blocklistProfile.editTitle
        : blocklistProfile.title;
    },
    /**
     * Page breadcrumbs
     * @type {Array}
     */
    breadcrumbs() {
      return [
        {
          to: this.$paths.accountSettings,
          query: { active_tab: "blocklist_monitoring" },
          title: this.$dictionary.app.accountSettings.title,
          type: this.$appConfig.breadcrumbs.types.route,
        },
        {
          title: this.title,
          type: this.$appConfig.breadcrumbs.types.route,
        },
      ];
    },
  },
};
</script>
