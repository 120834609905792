<template>
  <card-widget class="mt-8">
    <template #cardBody>
      <div id="blocklist-monitoring__header">
        <h1 class="text-h6 font-weight-large pb-2">
          Profile Information
        </h1>
        <h4 class="subtitle-1 text--secondary primary--font">
          Enter at least one Domain or IP Address to have monitored by the
          blocklist.
        </h4>
      </div>

      <v-row class="mt-4">
        <v-col>
          <v-text-field
            outlined
            clearable
            class="required"
            label="Profile Name"
            v-model="profile.name"
            :rules="[required('Profile Name')]"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-combobox
            multiple
            outlined
            :return-object="false"
            :loading="isLoading"
            small-chips
            v-model="profile.from_domains"
            :items="sendingDomains"
            item-text="from_domain"
            item-value="ip_address"
            item-color="primary custom-dropdown"
            label="Domain"
            :rules="[validateDomain, areDomainsValid('Domain')]"
          >
            <template v-slot:selection="{ item, attrs, selected, select }">
              <v-chip
                @click="select"
                v-bind="attrs"
                :input-value="selected"
                class="body-2 text--primary"
                :class="invalidChipClass(validateDomain)"
              >
                {{ item }}
                <v-icon
                  small
                  class="pl-2"
                  v-bind="chipErrorIconAttrs(validateDomain)"
                  @click="removeArrayItem(item, profile.from_domains)"
                >
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-2">
        <v-col>
          <v-combobox
            multiple
            outlined
            small-chips
            :return-object="false"
            item-value="sending_ip"
            label="IP Address"
            :items="sendingIPs"
            :loading="isLoading"
            item-text="sending_ip"
            v-model="profile.ip_addresses"
            item-color="primary custom-dropdown"
            :rules="[validateIps, areIPAddressesValid]"
          >
            <template v-slot:selection="{ item, attrs, selected, select }">
              <v-chip
                v-bind="attrs"
                :class="invalidChipClass(validateIps)"
                @click="select"
                :input-value="selected"
                class="body-2 text--primary"
              >
                {{ item }}
                <v-icon
                  small
                  class="pl-2"
                  v-bind="chipErrorIconAttrs(validateIps)"
                  @click="removeArrayItem(item, profile.ip_addresses)"
                >
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </template>
  </card-widget>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getPropArr,
  isEmpty,
  isType,
  removeArrayItem,
} from "@/utils/common.utils";
import {
  required,
  requiredMultiple,
  validateDomain,
  validateIPaddress,
} from "@/validators/form-valdators";

import CardWidget from "@/components/widgets/CardWidget.vue";

/**
 * Blocklist monitoring profile details
 * @author {Jatin Kamboj}
 */
export default {
  name: "BlocklistMonitoringProfileDetails",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { CardWidget },
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    profile: { type: Object, required: true },
    profileId: { type: [String, Number], required: true, default: "" },
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      isLoading: false,
    };
  },
  /**
  |--------------------------------------------------
  | Watching form properties
  |--------------------------------------------------
  */
  watch: {
    selectedAccountId() {
      if (!this.isAdmin || this.profileId) {
        this.$router.push({
          path: "/settings/account",
          query: { active_tab: "blocklist_monitoring" },
        });
      } else {
        this.fetchFormResources();
      }
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
      sendingIPs: "account/sendingIPs",
      sendingDomains: "account/sendingDomains",
      selectedAccountId: "ui/selectedAccountId",
      blocklistProfiles: "blocklist/blocklistProfiles",
    }),
    /**
     * List of blocklist monitoring profiles excluding current profile
     */
    existingBlocklistProfiles() {
      if (!this.profileId) return this.blocklistProfiles;
      return this.blocklistProfiles?.filter(
        ({ id }) => id !== Number(this.profileId)
      );
    },
    /**
     * Validates whether monitoring domain added by the user already exists in any other
     * monitoring profiles of selected account
     * @returns {Boolean | String}
     */
    validateDomain() {
      const { from_domains } = this.profile;
      const isValid = this.existingTargets("domain").every(
        (domain) => !from_domains.includes(domain)
      );

      return (
        isValid ||
        "Domain already being monitored and cannot be monitored in multiple profiles."
      );
    },
    /**
     * Validates IP address entered by the user are valid or not
     * @returns {Boolean | String}
     */
    areIPAddressesValid() {
      const validator = validateIPaddress("IP Address");
      return (
        this.profile.ip_addresses.every((ip) =>
          isType(validator(ip), "boolean")
        ) || "IP Address is not valid."
      );
    },
    /**
     * Validates monitoring Ip address added by the user
     * @returns {Boolean | String}
     */
    validateIps() {
      const { ip_addresses } = this.profile;
      const isValid = this.existingTargets("ip").every(
        (ipAddress) => !ip_addresses.includes(ipAddress)
      );

      return (
        isValid ||
        "IP Address is already being monitored and cannot be monitored in multiple profiles."
      );
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      getSendingIPs: "account/getSendingIPs",
      getSendingDomains: "account/getSendingDomains",
      getBlocklistProfiles: "blocklist/getBlocklistProfiles",
    }),
    /**
     * @param {String} type @values ip, domain
     */
    existingTargets(type = "ip") {
      const target = { ip: [], domain: [] };
      if (isEmpty(this.existingBlocklistProfiles)) return target[type];

      const targets = getPropArr(this.existingBlocklistProfiles, "targets")
        .flat()
        .reduce(this.computeTargets, { ...target });

      return targets[type];
    },
    /**
     * computeTargets
     * Compute targets of blocklist profiles
     */
    computeTargets(acc, curr) {
      const ipValidator = validateIPaddress("");
      if (isType(ipValidator(curr), "boolean")) acc["ip"].push(curr);
      else if (isType(validateDomain("")(curr), "boolean")) {
        acc["domain"].push(curr);
      }
      return acc;
    },
    required,
    validateIPaddress,
    areDomainsValid(fieldName) {
      return (domain) => {
        const validator = validateDomain(fieldName);
        const isDomainValid = domain.every((data) =>
          isType(validator(data), "boolean")
        );
        return isDomainValid || `${fieldName} is not valid.`;
      };
    },
    removeArrayItem,
    requiredMultiple,
    /**
     * chipErrorIconAttrs
     * @param {Boolean | String} isValid determines the validation status of input field
     * @returns {Object}
     */
    chipErrorIconAttrs(isValid) {
      return !isType(isValid, "boolean") ? { color: "error" } : {};
    },
    /**
     * Computes the class of the profile chips
     * @returns {String} class to be addedd at the chip
     */
    invalidChipClass(isValid) {
      return isValid && isType(isValid, "boolean")
        ? "filter-light-green-chip"
        : "chip-error";
    },
    /**
     * Fetches data properties to be rendered in the form dropdowns
     * for data selection
     */
    async fetchFormResources() {
      try {
        this.isLoading = true;

        const promises = [
          this.getSendingIPs({ account_id: this.selectedAccountId }),
          this.getSendingDomains({ account_ids: this.selectedAccountId }),
          this.getBlocklistProfiles({ account_id: this.selectedAccountId }),
        ];
        await Promise.allSettled(promises);
      } finally {
        this.isLoading = false;
      }
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.fetchFormResources();
  },
};
</script>
