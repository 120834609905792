<template>
  <div class="add-monitoring-schedule py-1">
    <v-row class="add-monitoring-schedule__wrapper">
      <v-col cols="12" lg="2" md="2" class="d-flex align-center justify-center">
        <label
          :for="`monitoring-schedule-${weekDay}-hours`"
          class="
            subtitle-1
            secondary--font
            font-weight-large
            text--secondary text-capitalize
          "
        >
          {{ weekDay }}
        </label>
      </v-col>

      <v-col cols="12" lg="10" md="10">
        <v-autocomplete
          :id="`monitoring-schedule-${weekDay}-hours`"
          label="Hours"
          outlined
          small-chips
          clearable
          multiple
          hide-details="auto"
          item-text="text"
          item-value="value"
          item-color="primary custom-dropdown"
          :items="hours"
          v-model="dayHours"
        >
          <template v-slot:item="{ on, attrs, item }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action class="mr-4">
                <v-checkbox :input-value="active" />
              </v-list-item-action>
              <v-list-item-content class="body-2 text--primary">
                {{ item.text }}
                <v-spacer />
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <v-chip
              close
              class="filter-light-green-chip body-2 text--primary"
              @click:close="removeArrayItem(item && item.value, schedule)"
            >
              <span v-text="item.text" />
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required } from "@/validators/form-valdators";
import { removeArrayItem } from "@/utils/common.utils";

import { DAY_HOURS, WEEK_DAYS } from "@/constants";

/**
 * Blocklist monitoring scheduler widget
 * @author {Jatin Kamboj}
 */
export default {
  name: "AddMonitoringSchedule",
  /**
  |--------------------------------------------------
  | Custom events
  |--------------------------------------------------
  */
  emits: ["delete-schedule"],
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    weekDay: { type: String, default: "", required: true },
    schedule: { type: Array, default: () => [], required: true },
  },
  /**
|--------------------------------------------------
| Data Attributes
|--------------------------------------------------
*/
  data() {
    return {
      weekDays: [...WEEK_DAYS],
      hours: [...DAY_HOURS],
      maxSelectedWeekdays: 3,
      maxSelectedHours: 3,
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    dayHours: {
      get() {
        return this.schedule;
      },
      set(val) {
        this.$emit("update-schedule", this.weekDay, val);
      },
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    required,
    removeArrayItem,
  },
};
</script>

<style lang="scss">
.blocklist-monitoring-week-days {
  .v-list-item--link {
    padding: 0px;
  }
}
</style>
