<template>
  <div class="blocklist-monitoring-profile__form">
    <loader :loading="isProfileLoading" :size="$appConfig.loader.small" />
    <v-form v-model="isFormValid" ref="form" @submit.prevent="submitHandler">
      <blocklist-monitoring-profile-details
        :profile="profile"
        :profile-id="profileId"
      />
      <card-widget class="mt-6">
        <template #cardBody>
          <div id="blocklist-monitoring__header">
            <h1
              class="text-h6 font-weight-large pb-2"
              v-text="'Monitoring Schedule'"
            />

            <h4 class="subtitle-1 text--secondary primary--font">
              Enter at least one schedule to have select domains and IP
              addresses checked on the blocklist.
            </h4>
          </div>

          <v-row class="mt-1">
            <v-col align-self="center" align="center">
              <v-progress-linear
                id="monthly-remmaining-lookups"
                rounded
                color="dark-red"
                :value="monthlyQuotaExhausted"
                height="8px"
                class="ml-3"
              />
            </v-col>
          </v-row>
          <v-row no-gutters class="mx-3">
            <v-col cols="12" class="d-flex">
              <div class="d-flex align-center">
                <v-avatar
                  tile
                  class="mr-1 rounded"
                  height="4px"
                  width="16px"
                  color="dark-red"
                  min-width="16px"
                  max-width="16px"
                />
                <span
                  class="text--secondary caption"
                  v-text="`${monthlyLookupsQuota} Monthly Lookups`"
                />
              </div>
              <div class="d-flex align-center">
                <v-avatar
                  tile
                  height="4px"
                  width="16px"
                  color="dark-red"
                  min-width="16px"
                  max-width="16px"
                  class="ml-2 mr-1 rounded lighten-4"
                />
                <span
                  class="text--secondary caption"
                  v-text="`${exhaustedLookups} Monthly Lookups`"
                />
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col>
              <v-autocomplete
                outlined
                label="Time Zone"
                item-text="name"
                :items="timezones"
                v-model="profile.timezone"
                hide-details="auto"
                item-value="identifier"
                item-color="primary custom-dropdown"
              />
            </v-col>
          </v-row>
          <template v-for="(schedule, name) in profile.schedule">
            <add-monitoring-schedule
              :key="name"
              @update-schedule="onUpdateSchedule"
              :schedule="schedule"
              :week-day="name"
            />
          </template>
        </template>
      </card-widget>

      <v-divider class="mt-8 mb-6 border-light-grey" />
      <v-row no-gutters class="d-flex justify-start">
        <v-col cols="12">
          <v-btn
            medium
            type="submit"
            color="primary"
            :disabled="isFormDisabled"
            :loading="isProfileCreating"
            class="px-4 button font-weight-bold white-btn-text"
            v-track="'save-blocklist-monitoring-profile-button'"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import CardWidget from "@/components/widgets/CardWidget.vue";
import {
  required,
  requiredMultiple,
  validateDomain,
  validateIPaddress,
} from "@/validators/form-valdators";

import AddMonitoringSchedule from "@/components/widgets/AddMonitoringSchedule.vue";
import {
  defer,
  isEmpty,
  isType,
  pick,
  removeArrayItem,
} from "@/utils/common.utils";
import {
  getBlocklistProfile,
  createBlocklistProfile,
  updateBlocklistProfile,
} from "@/services/blocklistProfiles";
import { RESPONSE_CODES, WEEK_DAYS } from "@/constants";
import BlocklistMonitoringProfileDetails from "../tabs/BlocklistMonitoringTab/BlocklistMonitoringProfileDetails.vue";
import clone from "lodash/clone";

/**
 * Blocklist monitoring profile form
 * @author {Jatin Kamboj}
 */
export default {
  name: "BlocklistMonitoringProfileForm",
  /**
    |--------------------------------------------------
    | Components
    |--------------------------------------------------
    */
  components: {
    CardWidget,
    AddMonitoringSchedule,
    BlocklistMonitoringProfileDetails,
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      profile: {
        name: "",
        timezone: "etc/utc",
        from_domains: [],
        ip_addresses: [],
        schedule: WEEK_DAYS.reduce((acc, curr) => {
          acc[curr] = [];
          return acc;
        }, {}),
      },

      isLoading: false,
      isProfileLoading: false,
      isProfileCreating: false,
      isFormValid: false,
      exhaustedLookups: 20,
      monitoringSchedulesLimit: 5,
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      isAdmin: "auth/isAdmin",
      timezones: "app/uniqTimezones",
      selectedAccountId: "ui/selectedAccountId",
      monthlyLookupsQuota: "ui/monthlyLookupsQuota",
    }),
    /**
     * Is form data valid
     * @type {Boolean}
     */
    isFormDisabled() {
      return (
        !this.isFormValid ||
        !this.isAdmin ||
        !this.areSchedulesValid ||
        !this.isFormdataValid
      );
    },
    /**
     * isFormdataValid
     * @description Determines that profile domains and ip_addresses both are not empty
     */
    isFormdataValid() {
      return (
        !isEmpty(this.profile?.from_domains) ||
        !isEmpty(this.profile?.ip_addresses)
      );
    },
    /**
     * Monlthy exhausted lookups quota percentage
     * @type {Number}
     */
    monthlyQuotaExhausted() {
      const { percent } = this.$options.filters;
      return Math.ceil(
        percent(this.exhaustedLookups, this.monthlyLookupsQuota)
      );
    },
    /**
     * Update monitoring profile
     * @type {Boolean}
     */
    isEditProfile() {
      return !!this.profileId;
    },
    /**
     * Are selected monitoring schedules valid
     * @type {Boolean}
     */
    areSchedulesValid() {
      return (
        !isEmpty(this.profile?.schedule) &&
        Object.keys(this.profile?.schedule).some(
          (weekDay) => !isEmpty(this.profile?.schedule[weekDay])
        )
      );
    },
    profileId() {
      return this.$route?.params?.profileId;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    required,
    removeArrayItem,
    requiredMultiple,
    onUpdateSchedule(schedule, hours) {
      this.profile.schedule[schedule] = hours;
    },
    ...mapMutations({
      hideSnackbar: "ui/HIDE_SNACKBAR",
    }),
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      getTimezones: "app/getTimezones",
    }),
    /**
     * Redirects to account settings blocklist monitoring tab
     */
    redirectToProfileTab() {
      const callback = () =>
        this.$router.push({
          path: "/settings/account",
          query: { active_tab: "blocklist_monitoring" },
        });
      defer(callback, 500);
    },
    /**
     * Fetches details of blocklist profile
     */

    async fetchProfileDetails() {
      if (!this.isEditProfile || !this.isAdmin) return;

      try {
        this.isProfileLoading = true;
        const { data } = await getBlocklistProfile(
          this.selectedAccountId,
          this.profileId
        );

        this.profile = this.computeProfileData(data);
      } finally {
        this.isProfileLoading = false;
      }
    },
    /**
     * Computes and populates monitoring profile data in the form
     */
    computeProfileData({ ip_addresses = [], from_domains = [], ...data }) {
      if (data?.targets) {
        data.targets.forEach((target) => {
          if (isType(validateIPaddress()(target), "boolean")) {
            ip_addresses.push(target);
          } else if (isType(validateDomain("")(target), "boolean"))
            from_domains.push(target);
        });
      }

      return {
        from_domains,
        ip_addresses,
        ...pick(data, ["id", "name", "timezone", "schedule", "status"]),
        schedule: { ...this.profile.schedule, ...data.schedule },
      };
    },
    /**
     * FOrm submit handler
     */
    submitHandler() {
      if (!this.isAdmin) return;
      if (this.isEditProfile) this.updateBlocklistProfileApi();
      else this.createBlocklistProfileHandler();
    },
    /**
     * @description Creates a new bloclist monitoring ptofile
     */
    async createBlocklistProfileHandler() {
      try {
        this.isProfileCreating = true;

        const payload = [
          this.selectedAccountId,
          { profile: this.profileDetailsPayload(this.profile) },
        ];
        const { status } = await createBlocklistProfile(...payload);

        if (status === RESPONSE_CODES.created) {
          this.setSnackbar({
            value: true,
            message: "Profile successfully added.",
            type: this.$appConfig.snackbar.snackbarTypes.success,
          });
          this.redirectToProfileTab();
        }
      } finally {
        this.isProfileCreating = false;
      }
    },
    /**
     * profileDetailsPayload
     * Computes profile request payload
     */
    profileDetailsPayload(profileData) {
      const profile = clone(profileData);
      const targets = [...profile.from_domains, ...profile.ip_addresses];

      delete profile.from_domains;
      delete profile.ip_addresses;
      return { ...profile, targets };
    },
    /**
     * Computes the update montoring profile request payload
     * @returns {Array}
     */
    updateProfilePayload() {
      return [
        this.selectedAccountId,
        { profile: this.profileDetailsPayload(this.profile) },
        this.profile.id,
      ];
    },
    /**
     * Updates a blocklist monitoring profile details
     */
    async updateBlocklistProfileApi() {
      try {
        this.isProfileCreating = true;

        await updateBlocklistProfile(...this.updateProfilePayload());
        this.setSnackbar({
          value: true,
          message: "Profile changes successfully saved.",
          type: this.$appConfig.snackbar.snackbarTypes.success,
        });
        this.redirectToProfileTab();
      } finally {
        this.isProfileCreating = false;
      }
    },
    /**
     * Fecthes form required resources
     */
    async fetchResources() {
      try {
        this.isProfileLoading = true;
        await Promise.allSettled([
          this.getTimezones(),
          this.fetchProfileDetails(),
        ]);
      } finally {
        this.isProfileLoading = false;
      }
    },
  },
  /**
  |--------------------------------------------------
  | Created lifecycle hook
  |--------------------------------------------------
  */
  created() {
    this.fetchResources();
  },
};
</script>

<style lang="scss" scoped>
#schedules-error-message {
  width: 300px !important;
}
</style>
