var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-monitoring-schedule py-1"},[_c('v-row',{staticClass:"add-monitoring-schedule__wrapper"},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","lg":"2","md":"2"}},[_c('label',{staticClass:"\n          subtitle-1\n          secondary--font\n          font-weight-large\n          text--secondary text-capitalize\n        ",attrs:{"for":("monitoring-schedule-" + _vm.weekDay + "-hours")}},[_vm._v(" "+_vm._s(_vm.weekDay)+" ")])]),_c('v-col',{attrs:{"cols":"12","lg":"10","md":"10"}},[_c('v-autocomplete',{attrs:{"id":("monitoring-schedule-" + _vm.weekDay + "-hours"),"label":"Hours","outlined":"","small-chips":"","clearable":"","multiple":"","hide-details":"auto","item-text":"text","item-value":"value","item-color":"primary custom-dropdown","items":_vm.hours},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-4"},[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',{staticClass:"body-2 text--primary"},[_vm._v(" "+_vm._s(item.text)+" "),_c('v-spacer')],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"filter-light-green-chip body-2 text--primary",attrs:{"close":""},on:{"click:close":function($event){return _vm.removeArrayItem(item && item.value, _vm.schedule)}}},[_c('span',{domProps:{"textContent":_vm._s(item.text)}})])]}}]),model:{value:(_vm.dayHours),callback:function ($$v) {_vm.dayHours=$$v},expression:"dayHours"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }