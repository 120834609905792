var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card-widget',{staticClass:"mt-8",scopedSlots:_vm._u([{key:"cardBody",fn:function(){return [_c('div',{attrs:{"id":"blocklist-monitoring__header"}},[_c('h1',{staticClass:"text-h6 font-weight-large pb-2"},[_vm._v(" Profile Information ")]),_c('h4',{staticClass:"subtitle-1 text--secondary primary--font"},[_vm._v(" Enter at least one Domain or IP Address to have monitored by the blocklist. ")])]),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-text-field',{staticClass:"required",attrs:{"outlined":"","clearable":"","label":"Profile Name","rules":[_vm.required('Profile Name')]},model:{value:(_vm.profile.name),callback:function ($$v) {_vm.$set(_vm.profile, "name", $$v)},expression:"profile.name"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-combobox',{attrs:{"multiple":"","outlined":"","return-object":false,"loading":_vm.isLoading,"small-chips":"","items":_vm.sendingDomains,"item-text":"from_domain","item-value":"ip_address","item-color":"primary custom-dropdown","label":"Domain","rules":[_vm.validateDomain, _vm.areDomainsValid('Domain')]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var selected = ref.selected;
var select = ref.select;
return [_c('v-chip',_vm._b({staticClass:"body-2 text--primary",class:_vm.invalidChipClass(_vm.validateDomain),attrs:{"input-value":selected},on:{"click":select}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" "),_c('v-icon',_vm._b({staticClass:"pl-2",attrs:{"small":""},on:{"click":function($event){return _vm.removeArrayItem(item, _vm.profile.from_domains)}}},'v-icon',_vm.chipErrorIconAttrs(_vm.validateDomain),false),[_vm._v(" mdi-close-circle ")])],1)]}}]),model:{value:(_vm.profile.from_domains),callback:function ($$v) {_vm.$set(_vm.profile, "from_domains", $$v)},expression:"profile.from_domains"}})],1)],1),_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-combobox',{attrs:{"multiple":"","outlined":"","small-chips":"","return-object":false,"item-value":"sending_ip","label":"IP Address","items":_vm.sendingIPs,"loading":_vm.isLoading,"item-text":"sending_ip","item-color":"primary custom-dropdown","rules":[_vm.validateIps, _vm.areIPAddressesValid]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var selected = ref.selected;
var select = ref.select;
return [_c('v-chip',_vm._b({staticClass:"body-2 text--primary",class:_vm.invalidChipClass(_vm.validateIps),attrs:{"input-value":selected},on:{"click":select}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item)+" "),_c('v-icon',_vm._b({staticClass:"pl-2",attrs:{"small":""},on:{"click":function($event){return _vm.removeArrayItem(item, _vm.profile.ip_addresses)}}},'v-icon',_vm.chipErrorIconAttrs(_vm.validateIps),false),[_vm._v(" mdi-close-circle ")])],1)]}}]),model:{value:(_vm.profile.ip_addresses),callback:function ($$v) {_vm.$set(_vm.profile, "ip_addresses", $$v)},expression:"profile.ip_addresses"}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }